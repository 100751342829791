<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="8">
          <a-col :md="6" :sm="24">
            <a-form-item label="客户名称">
              <a-input v-model="queryParam.nicknameLike" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="签约人">
              <a-input v-model="queryParam.signatoryLike" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="使用状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="1">正常</a-select-option>
                <a-select-option value="0">禁用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="(!advanced && 6) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd()" v-action:but_customer1_add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 1200, y: scrollHeight }"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="downloadht(record)" v-action:but_customer1_edit>合同下载&ensp;|&ensp;</a>
          <a @click="handleEdit(record)" v-action:but_customer1_edit>编辑&ensp;|&ensp;</a>
          <a @click="updateStatus(record)" v-action:but_customer1_disable>{{ record.status == 1 ? '禁用' : '启用' }}</a>
          <!--如果需要权限 v-action:roleBD -->
        </template>
      </span>
    </s-table>

    <a-modal
      key="addCustomer"
      title="新增/编辑"
      :width="550"
      :visible="visible_add"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="addCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div>

          <a-form @submit="handleSubmit" :form="form">
            <a-form-item
              label="客户名称"
              :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="['nickname', { rules: [{ required: true, message: '请输入名称' }] }]"
                name="nickname"
              />
            </a-form-item>

            <a-form-item
              label="签约人"
              :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
            >
              <a-select
                show-search
                placeholder="请选择"
                :filter-option="filterOption"
                @change="handleChange"
                v-decorator="['signatoryId']"
              >
                <a-select-option v-for="(item, index) in optionssignatory" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              label="收取装卸费"
              :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
            >
              <a-radio-group v-model="isHandlingFee" v-decorator="['isHandlingFee']" @change="onChange1" :default-value="defalutIsHandlingFee">
                <a-radio value="0">
                  否
                </a-radio>
                <a-radio value="1">
                  是
                </a-radio>
              </a-radio-group>
              <a-input style="width: 235px" :readonly="isHandlingFee==0" placeholder="请输入每公斤单价元 例如 2.5"
                       v-decorator="['handlingUnitPrice', { rules: [{ required: false, message: '请输入单价' }] }]"
                       name="handlingUnitPrice"
              />
            </a-form-item>

            <a-form-item
              label="收取托盘费"
              :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
            >
              <a-radio-group v-model="isPalletFee" v-decorator="['isPalletFee']"  @change="onChange2" :default-value="defalutIsPalletFee">
                <a-radio value="0">
                  否
                </a-radio>
                <a-radio value="1">
                  是
                </a-radio>
              </a-radio-group>
              <a-input style="width: 235px" :readonly="isPalletFee==0" placeholder="请输入每个单价元 例如 5"
                       v-decorator="['palletUnitPrice', { rules: [{ required: false, message: '请输入单价' }] }]"
                       name="palletUnitPrice"
              />
            </a-form-item>

            <a-form-item
              label="收取分拣费"
              :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
            >
              <a-radio-group v-model="isSortingFee" v-decorator="['isSortingFee']"  @change="onChange4" :default-value="defalutIsSortingFee">
                <a-radio value="0">
                  否
                </a-radio>
                <a-radio value="1">
                  是
                </a-radio>
              </a-radio-group>
              <a-input style="width: 235px" :readonly="isSortingFee==0" placeholder="请输入每件单价元 例如 0.5"
                       v-decorator="['sortingFee', { rules: [{ required: false, message: '请输入单价' }] }]"
                       name="sortingFee"
              />
            </a-form-item>

            <a-form-item
              label="托盘个数计算规则"
              :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
            >
              <a-input style="width: 128px"
                v-decorator="['palletNuRule', { rules: [{ required: false, message: '请输入数值' }] }]" placeholder="请输入数值"
                name="palletNuRule"
              /> <label style="padding-left: 10px">立方米需要一个托盘</label>
            </a-form-item>

            <a-form-item
              label="合同"
              :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
            >
              <a-upload
                name="file"
                :max-count="1"
                class="avatar-uploader"
                :show-upload-list="false"
                :customRequest="customRequest"
                @change="uploadChange"
              >
                <a-button id="showfilename" style="width: 100%">
                  {{ thisCustomr.contractFile == null ? '上传文件' : thisCustomr.contractFile }}
                </a-button>
              </a-upload>
            </a-form-item>

            <a-form-item
              label="结算方式"
              :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
            >
              <a-select placeholder="请选择结算类型" @change="handleChange2"
                        v-decorator="['settlementType', { rules: [{ required: true, message: '请选择结算类型' }] }]">
                <a-select-option value="1">现付</a-select-option>
                <a-select-option value="2">月结</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              label="报价标签"
              :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
            >
              <a-select placeholder="请选择报价"
                        @change="handleChange1"
                        v-decorator="['quotationId']"
                        @dropdownVisibleChange="initQuotationCustomerPriceList"
                        style="width: 235px;padding-right: 10px">
                <a-select-option v-for="(item, index) in optionsquoted" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <a-button @click="gonewbq">新增报价标签</a-button>
            </a-form-item>

            <a-form-item
              label="单票最低收费限制"
              :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
            >
              <a-switch :default-checked="defaultChecked3" @change="onChange3" style="width: 23px;padding-right: 10px"/>
              <a-icon style="font-size: 22px;padding-left: 5px;color: #cccccc" type="question-circle" title="开启：受单票最低收费限制，每单不可低于对应始发地最低收费，低于最低收费按最低收费计费生成订单；
关闭：不受单票最低收费限制，每单可低于对应始发地最低收费，按实际报价计费生成订单;">
             </a-icon>
            </a-form-item>

            <a-form-item :wrapperCol="{ span: 24 }" class="form-submit" style="padding-right: 20px">
              <a-button style="margin-right: 8px" @click="addCancel">取消</a-button>
              <a-button htmlType="submit" type="primary">提交</a-button>
            </a-form-item>
          </a-form>

      </div>
    </a-modal>

    <customer-price-form
      :visible="modalAddEditVisible"
      :confirm="modalAddEditConfirm"
      :cancel="modalAddEditCancel"
    ></customer-price-form>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { UploadOutlined } from '@ant-design/icons-vue'
import Vue, { defineComponent, ref } from 'vue'
import { UploadChangeParam } from 'ant-design-vue'
import {
  findPageCustomer,
  updateCustomer,
  saveCustomer,
  deleteCustomer,
  getUserList,
  quotationCustomerPriceList,
} from '@/api/manage'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { put } from '@/utils/upload'
import CustomerPriceForm from '@/views/quotation/module/CustomerPriceForm'
export default {
  name: 'TableList',
  components: {
    STable,
    UploadOutlined,
    CustomerPriceForm,
  },
  data() {
    return {
      mdl: {},
      openKeys: ['1'],
      idi: 2,
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: { pageSizeOptions: ['50', '100', '200', '500'] },
      treeData: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '识别码',
          width: '150px',
          key: 'code',
          dataIndex: 'code',
          align: 'center',
        },
        {
          title: '客户名称',
          width: '250px',
          key: 'nickname',
          dataIndex: 'nickname',
          align: 'center',
        },
        {
          title: '签约人',
          width: '150px',
          key: 'signatory',
          dataIndex: 'signatory',
          align: 'center',
        },
        {
          title: '收取装卸费',
          width: '150px',
          key: 'isHandlingFee',
          dataIndex: 'isHandlingFee',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '是'
            } else {
              ret = '否'
            }
            return ret
          },
        },
        {
          title: '装卸费单价',
          width: '150px',
          key: 'handlingUnitPrice',
          dataIndex: 'handlingUnitPrice',
          align: 'center',
        },
        {
          title: '收取托盘费',
          width: '150px',
          key: 'isPalletFee',
          dataIndex: 'isPalletFee',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '是'
            } else {
              ret = '否'
            }
            return ret
          },
        },
        {
          title: '托盘单价',
          width: '150px',
          key: 'palletUnitPrice',
          dataIndex: 'palletUnitPrice',
          align: 'center',
        },
        {
          title: '收取分拣费',
          width: '150px',
          key: 'isSortingFee',
          dataIndex: 'isSortingFee',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '是'
            } else {
              ret = '否'
            }
            return ret
          },
        },
        {
          title: '分拣费单价',
          width: '150px',
          key: 'sortingFee',
          dataIndex: 'sortingFee',
          align: 'center',
        },
        {
          title: '单票最低收费限制',
          width: '180px',
          key: 'minLimit',
          dataIndex: 'minLimit',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '是'
            } else {
              ret = '否'
            }
            return ret
          },
        },
        {
          title: '托盘个数计算规则',
          width: '180px',
          key: 'palletNuRule',
          dataIndex: 'palletNuRule',
          align: 'center'
        },
        {
          title: '状态',
          width: '120px',
          key: 'status',
          dataIndex: 'status',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '正常'
            } else {
              ret = '禁用'
            }
            return ret
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '200px',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        console.log('loadData.parameter', parameter)
        let param = {}
        param = Object.assign(parameter, this.queryParam)
        param.type = 1
        return findPageCustomer(param).then((res) => {
          return res.result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
        },
      },
      optionAlertShow: false,
      confirmLoading: false,
      visible_add: false,
      visible_add2:false,
      form: this.$form.createForm(this),
      tabObj: {}, //当前对象，编辑是用到
      addFlag: true, //true新增false编辑
      expandedKeys: [], //已绑定的keys
      submitKeys: [], //提交保存的key,包括半选中和选中
      optionssignatory: [], //签约人候选[{ value: 'jack0', label: 'Jack' },{ value: 'lucy0', label: 'Lucy' }]
      optionsquoted: [], //报价标签候选[{ value: 1, label: '报价1' },{ value: 2, label: '报价2' }]
      optionssettle: [
        { value: 1, label: '现付' },
        { value: 2, label: '月结' },
      ], //结算方式候选
      fileList: [],
      thisCustomr: {},
      defaultCustomr: {},
      scrollHeight: 0,
      isHandlingFee:0,
      isPalletFee:0,
      isSortingFee:0,
      defalutIsHandlingFee:"0",
      defalutIsPalletFee:"0",
      defalutIsSortingFee:"0",
      modalAddEditVisible:false,
      defaultChecked3:false,
      checked3:false
    }
  },
  created() {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight - 400
  },
  filters: {},
  methods: {
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            },
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          },
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null,
        }
        this.optionAlertShow = false
      }
    },
    handleEdit(record) {
      let that = this
      this.visible_add = true
      this.thisCustomr = {}
      this.tabObj = record
      this.addFlag = false
      //初始化编辑框内容
      this.thisCustomr.contractFile = record.contractFile
      this.isPalletFee = record.isPalletFee
      this.isHandlingFee=record.isHandlingFee
      this.isSortingFee = record.isSortingFee
      this.defalutIsHandlingFee=record.isHandlingFee+""
      this.defalutIsPalletFee=record.isPalletFee+""
      this.defalutIsSortingFee=record.isSortingFee+""

      if(record.minLimit!=null&&record.minLimit==1){
        this.checked3 = true
        this.defaultChecked3 = true
      }else{
        this.checked3 = false
        this.defaultChecked3 = false
      }

      //初始化签约人候选数据
      //初始化报价列表数据
      this.$nextTick(() => {
        getUserList({ status: 1 })
          .then((res) => {
            if (res != null && res.result != null) {
              that.optionssignatory = []
              for (let i in res.result) {
                that.optionssignatory.push({ value: res.result[i].id + '', label: res.result[i].name })
              }
            }
          })
          .then(() => {
            that.form.setFieldsValue({
              nickname: record.nickname,
              handlingUnitPrice: record.handlingUnitPrice,
              palletUnitPrice: record.palletUnitPrice,
              sortingFee: record.sortingFee,
              settlementType: record.settlementType + '',
              signatoryId: record.signatoryId==null?"":record.signatoryId+"",
              contractFile: record.contractFile,
              palletNuRule: record.palletNuRule,
            })
          })
        quotationCustomerPriceList({ customerType: 2 })
          .then((res) => {
            if (res != null && res.result != null) {
              that.optionsquoted = []
              for (let i in res.result) {
                that.optionsquoted.push({ value: res.result[i].id, label: res.result[i].name })
              }
            }
          })
          .then(
            that.form.setFieldsValue({
              quotationId: record.quotationId,
            })
          )
      })
    },
    handleDel(record) {
      deleteCustomer(record)
        .then((res) => {
          //提交成功
          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('删除失败')
        })
    },
    updateStatus(record) {
      let targetstatus = 1
      if (record.status == 1) {
        targetstatus = 0
      }
      updateCustomer({ id: record.id, status: targetstatus })
        .then((res) => {
          //提交成功
          this.$message.success('更新成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('更新失败')
        })
    },
    handleOk() {},
    onChange3(e){
      this.checked3 = e
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      }
    },
    addCancel() {
      this.visible_add = false
    },
    handleAdd() {
      let that = this
      this.visible_add = true
      this.tabObj = {}
      this.addFlag = true
      this.thisCustomr = {}
      this.defalutIsHandlingFee="0"
      this.defalutIsPalletFee="0"
      //初始化签约人候选数据
      getUserList({ status: 1 }).then((res) => {
        if (res != null && res.result != null) {
          that.optionssignatory = []
          for (let i in res.result) {
            that.optionssignatory.push({ value: res.result[i].id, label: res.result[i].name })
          }
        }
      })
     this.initQuotationCustomerPriceList()
    },
    handleSubmit(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          //金额判断
          var reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/

          if (values.handlingUnitPrice==null || values.handlingUnitPrice=="" ||  reg.test(values.handlingUnitPrice)) {
          } else {
            this.$message.error("请正确输入装卸费单价")
            return false
          }
          if(values.palletUnitPrice==null || values.palletUnitPrice=="" || reg.test(values.palletUnitPrice)){
          }else{
            this.$message.error("请正确输入托盘费单价")
            return false
          }

          values.contractFile = this.thisCustomr.contractFile
          values.type = 1
          if(this.checked3){
            values.minLimit = 1
          }else{
            values.minLimit = 0
          }

          if(that.tabObj.id !=null){
            values.id = that.tabObj.id
            updateCustomer(values)
              .then((res) => {
                //提交成功
                this.$message.success('保存成功')
                that.visible_add = false
                this.$refs.table.refresh()
              }).catch((e) => {
              this.$message.error('保存失败')
            })
          }else{
            saveCustomer(values)
              .then((res) => {
                //提交成功
                this.$message.success('保存成功')
                that.visible_add = false
                this.$refs.table.refresh()
              }).catch((e) => {
              this.$message.error('保存失败')
            })
          }


        }
      })
    },
    initQuotationCustomerPriceList(e,selectedid){
      //初始化报价列表数据
      let that = this
      quotationCustomerPriceList({ customerType: 2 }).then((res) => {
        if (res != null && res.result != null) {
          that.optionsquoted = []
          for (let i in res.result) {
            that.optionsquoted.push({ value: res.result[i].id, label: res.result[i].name })
          }
          if(selectedid!=undefined && selectedid!=null){
            //默认选中此标签
            that.form.setFieldsValue({
              quotationId: selectedid,
            })
          }
        }
      })
    },
    iscandel(record) {
      if (record.type == 1) {
        return false
      } else {
        return true
      }
    },
    handleChange(value) {
      this.thisCustomr.signatoryId = value
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleChange1(value) {
      this.thisCustomr.quotedId = value
    },
    handleChange2(value) {
      this.thisCustomr.settlementType = value
    },
    uploadChange(info) {
      let that = this
    },
    customRequest(action) {
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          // this.loading = false
          // console.info("-----")
          let fileName = result.name
          this.thisCustomr.contractFile = fileName
          document.getElementById('showfilename').innerText = fileName
        })
        .catch((e) => {
          console.info(e)
        })
    },
    onChange1(e){
      if(e.target.value=="1"){
        this.isHandlingFee = 1
      }else{
        this.isHandlingFee = 0
        this.form.setFieldsValue({
          'handlingUnitPrice':undefined
        })
      }
    },
    onChange2(e){
      if(e.target.value=="1"){
        this.isPalletFee = 1
      }else{
        this.isPalletFee = 0
        this.form.setFieldsValue({
          'palletUnitPrice':undefined
        })
      }
    },
    onChange4(e){
      if(e.target.value=="1"){
        this.isSortingFee = 1
      }else{
        this.isSortingFee = 0
        this.form.setFieldsValue({
          'sortingFee':undefined
        })
      }
    },
    vjine(rule, value, callback){
      var reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
      if(value==null||value==""){
        return  true
      }else{
        if (reg.test(value)) {
          return true
        } else {
          return false
        }
      }
    },
    downloadht(record){
      console.log("查看合同")
      let url = '/service_provider/upload/getPic?fileName=' + record.contractFile
      let filename = record.contractFile.substr(record.contractFile.lastIndexOf("/")+1);
      this.getimgblob(url,filename)
    },
    gonewbq(){
      //打开报价标签新增弹框
      this.modalAddEditVisible = true
    },
    addCancel2(){
      this.visible_add2=false
    },
    modalAddEditConfirm(e,a){
        this.modalAddEditVisible = false

        this.initQuotationCustomerPriceList(true,a)

    },
    modalAddEditCancel() {
      this.modalAddEditVisible = false
    },
    handlePriceTypeChange(e) {
      this.fieldPriceType = e.target.value
    },
    async getimgblob(url,filename) {
      let token = Vue.ls.get(ACCESS_TOKEN)
      let request = new XMLHttpRequest()
      request.responseType = 'blob'
      request.open('get', url, true)
      // 携带请求头
      request.setRequestHeader('token', token)
      request.onreadystatechange = (e) => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
         // document.getElementById(id).src = URL.createObjectURL(request.response)
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(request.response,filename);
          } else {
            var a = document.createElement('a');
            var url =  URL.createObjectURL(request.response)
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
        }
      }
      request.send(null)
    },
  },
  computed: {
    headers() {
      let token = Vue.ls.get(ACCESS_TOKEN)
      return {
        token: token,
      }
    },
  },
}
</script>
<style lang="less" scoped>
.table-operator {
  margin-bottom: 10px;
}
/deep/ .ant-form-item-label {
  text-align: left;
  width: 30%;
}
/deep/ .ant-upload {
  width: 100%;
}
/deep/ .ant-form-item-control-wrapper{
  width: 70%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
}
</style>